// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-challenge-index-js": () => import("./../src/templates/challenge/index.js" /* webpackChunkName: "component---src-templates-challenge-index-js" */),
  "component---src-templates-event-index-js": () => import("./../src/templates/event/index.js" /* webpackChunkName: "component---src-templates-event-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-design-system-index-js": () => import("./../src/pages/design-system/index.js" /* webpackChunkName: "component---src-pages-design-system-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

