import text from "./text";

export default {
  "600": {
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
    "@media screen and (min-width: 1312px)": {
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  "500": {
    ...text["500"],
    lineHeight: "24px",
  },
  "400": {
    ...text["400"],
    lineHeight: "20px",
  },
};
