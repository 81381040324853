import React from "react";
import styled from "@emotion/styled";
import css from "@styled-system/css";

import { Box } from "components";
import headings from "components/theme/src/headings";
import paragraph from "components/theme/src/paragraph";

const StyledBox = styled(Box)((props) =>
  css({
    "*": {
      mt: "layout.1",
      mb: 0,
      color: props.color ? props.color : "text.1",
      ":first-child": { mt: 0 },
    },
    h1: {
      ...headings["900"],
    },
    h2: {
      ...headings["800"],
    },
    h3: {
      ...headings["700"],
    },
    h4: {
      ...headings["600"],
    },
    h5: {
      ...headings["500"],
    },
    h6: {
      ...headings["100"],
    },
    p: {
      ...paragraph[props.size],
    },
    a: {
      fontWeight: 500,
      textDecoration: "underline",
      color: "primary",
    },
    strong: {
      fontWeight: "bold",
    },
    em: {
      fontStyle: "italic",
    },
    ".block-img": {
      img: { maxWidth: "100%" },
    },
    '[data-oembed-type="video"]': {
      position: "relative",
      pb: (270 / 480) * 100 + "%",
      iframe: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      },
    },
  })
);

const RichText = ({ html, ...rest }) => (
  <StyledBox
    data-component-id="richText"
    dangerouslySetInnerHTML={{ __html: html }}
    {...rest}
  />
);

RichText.defaultProps = {
  size: "500",
};

export default RichText;
