import React from "react";
import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby";
import Image from "gatsby-image/withIEPolyfill";

import {
  Box,
  Button,
  Column,
  Columns,
  Heading,
  HStack,
  IconButton,
  Link,
  Text,
  Wrapper,
} from "components";

function Header() {
  const { settings, home } = useStaticQuery(SETTINGS_QUERY);
  const { homeData } = home;
  const { pageData } = settings;
  return (
    <Box
      data-component-id="header"
      as="header"
      position="absolute"
      top={0}
      left={0}
      zIndex={1}
      width="100%"
    >
      <Wrapper py="layout.1">
        <Columns>
          <Column>
            <GatsbyLink to="/">
              <Box
                display="inline-block"
                ml="-4px"
                css={{ verticalAlign: "top" }}
              >
                <HStack align="center" space="spacing.3">
                  <Box height={40} width={40}>
                    {pageData.ccc_logo && pageData.ccc_logo.fluid && (
                      <Image
                        fluid={pageData.ccc_logo.fluid}
                        alt="Calgary Corporate Challenge Logo"
                        objectFit="contain"
                        style={{ height: "100%" }}
                      />
                    )}
                  </Box>
                  <HStack>
                    <Box display={["none", "none", "block"]}>
                      <Heading
                        size={400}
                        fontWeight={700}
                        color="text.0"
                        css={{ textTransform: "uppercase" }}
                      >
                        {homeData.title && homeData.title.text}
                      </Heading>
                    </Box>
                  </HStack>
                </HStack>
              </Box>
            </GatsbyLink>
          </Column>
          <Column width="content">
            <Box>
              <HStack as="ul" space="spacing.2" align="center">
                {pageData.social_media_links.map(({ label, link }, index) => (
                  <IconButton
                    as={Link}
                    href={link.url ? link.url : ""}
                    symbol={
                      label.toLowerCase() +
                      (label.toLowerCase() === "instagram" ? "-line" : "-fill")
                    }
                    intent="default"
                    appearance="minimal"
                    height={40}
                    key={"headerSocialMediaLinks" + index}
                  />
                ))}
                <Button
                  as="a"
                  href="https://dashboard.calgarycorporatechallenge.com"
                  children="Dashboard"
                />
              </HStack>
            </Box>
          </Column>
        </Columns>
      </Wrapper>
    </Box>
  );
}

const SETTINGS_QUERY = graphql`
  {
    home: prismicHome {
      homeData: data {
        title {
          text
        }
      }
    }
    settings: prismicSettings {
      pageData: data {
        ccc_logo {
          fluid(maxWidth: 512) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        social_media_links {
          label
          link {
            url
          }
        }
      }
    }
  }
`;

export default Header;
