/**
 * Color Mode
 *
 * By default, the application renders with a light theme.
 * However, to easily swap to a dark palette, we provide a simple component to wrap
 * that hotswaps the default colors. This allows us to toggle between light and dark
 * more reliably than simply overriding.
 */

import React from "react"
import merge from "lodash.merge"
import get from "lodash.get"
import { ThemeProvider } from "emotion-theming"

import { Theme } from "components"

const ColorMode = ({ children, mode }) => {
  return (
    <ThemeProvider
      theme={merge({}, Theme, {
        colors: get(Theme.colors.modes, mode, Theme.colors),
      })}
    >
      {children}
    </ThemeProvider>
  )
}

export default ColorMode
