import React from "react";
import Image from "gatsby-image/withIEPolyfill";

import { Box, HStack, Link, Text, VStack, Wrapper } from "components";

const LOGO_SIZE = 96;

const Bottom = ({ pageData }) => (
  <Wrapper
    id="bottom"
    py={{ _: "layout.4", lg: "layout.5" }}
    borderTopWidth={1}
    overflow="visible"
  >
    <Link href="/">
      <Box
        width={LOGO_SIZE}
        height={LOGO_SIZE}
        mt={{ _: -48 - LOGO_SIZE / 2, lg: -64 - LOGO_SIZE / 2 }}
        mx="auto"
      >
        {pageData.ccc_badge && pageData.ccc_badge.fluid && (
          <Image
            fluid={pageData.ccc_badge.fluid}
            alt="Calgary Corporate Challenge Logo"
            objectFit="contain"
          />
        )}
      </Box>
    </Link>
    <Box pt={{ _: "layout.3", lg: "layout.4" }}>
      <VStack align="center" space="layout.1">
        <HStack as="ul" space="layout.1">
          {pageData.social_media_links.map(({ label, link }, index) => (
            <Text
              children={label}
              size={400}
              textAlign={{ _: "center", lg: "end" }}
              color="text.0"
              key={"socialMediaLinks" + index}
            >
              <Link href={link.url ? link.url : ""}>{label}</Link>
            </Text>
          ))}
        </HStack>
        <Text size={400} textAlign="center" color="text.2">
          © {new Date().getFullYear()} {pageData.footer_text}
        </Text>
      </VStack>
    </Box>
  </Wrapper>
);

export default Bottom;
