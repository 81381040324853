import fonts from "./fonts";

export default {
  "900": {
    fontFamily: fonts.display,
    fontSize: "32px",
    fontWeight: 600,
    lineHeight: "40px",
    "@media screen and (min-width: 672px)": {
      fontSize: "36px",
      lineHeight: "44px",
    },
    "@media screen and (min-width: 1056px)": {
      fontSize: "42px",
      lineHeight: "50px",
    },
    "@media screen and (min-width: 1312px)": {
      fontSize: "48px",
      lineHeight: "56px",
    },
    "@media screen and (min-width: 1584px)": {
      fontSize: "60px",
      lineHeight: "70px",
    },
  },
  "800": {
    fontFamily: fonts.display,
    fontSize: "32px",
    fontWeight: 300,
    lineHeight: "40px",
    "@media screen and (min-width: 672px)": {
      fontSize: "36px",
      lineHeight: "44px",
    },
    "@media screen and (min-width: 1056px)": {
      fontSize: "42px",
      lineHeight: "50px",
    },
    "@media screen and (min-width: 1312px)": {
      fontSize: "48px",
      lineHeight: "56px",
    },
    "@media screen and (min-width: 1584px)": {
      fontSize: "60px",
      lineHeight: "70px",
    },
  },
  "700": {
    fontFamily: fonts.display,
    fontSize: "28px",
    fontWeight: 600,
    lineHeight: "36px",
    "@media screen and (min-width: 1312px)": {
      fontSize: "32px",
      lineHeight: "40px",
    },
  },
  "600": {
    fontFamily: fonts.display,
    fontSize: "28px",
    fontWeight: 400,
    lineHeight: "36px",
    "@media screen and (min-width: 1312px)": {
      fontSize: "32px",
      lineHeight: "40px",
    },
  },
  "500": {
    fontFamily: fonts.display,
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "24px",
    "@media screen and (min-width: 1584px)": {
      fontSize: "24px",
      lineHeight: "28px",
    },
  },
  "400": {
    fontFamily: fonts.display,
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
  },
  "300": {
    fontFamily: fonts.display,
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
  },
  "200": {
    fontFamily: fonts.display,
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "16px",
  },
  "100": {
    fontFamily: fonts.display,
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "16px",
    textTransform: "uppercase",
  },
};
