import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { Box } from "components";

import FAQs from "./FAQs";
import Newsletter from "./Newsletter";
import Sponsors from "./Sponsors";
import Bottom from "./Bottom";

function Footer() {
  const { settings } = useStaticQuery(SETTINGS_QUERY);
  const { pageData } = settings;
  return (
    <Box data-component-id="footer" as="footer" mt="auto" pt="layout.7">
      <FAQs pageData={pageData} />
      <Newsletter pageData={pageData} />
      <Sponsors pageData={pageData} />
      <Bottom pageData={pageData} />
    </Box>
  );
}

const SETTINGS_QUERY = graphql`
  {
    settings: prismicSettings {
      pageData: data {
        ccc_badge {
          fluid(maxWidth: 512) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        faq_items {
          answer {
            html
          }
          button_label
          question
          link {
            url
          }
        }
        newsletter_title {
          text
        }
        newsletter_subtitle
        newsletter_email_prompt
        newsletter_button_label
        sponsor_title
        sponsors {
          sponsor_logo {
            fluid(maxWidth: 256) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          sponsor_name
        }
        footer_text
        social_media_links {
          label
          link {
            url
          }
        }
      }
    }
  }
`;

export default Footer;
