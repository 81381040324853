import React from "react";

import {
  Button,
  Card,
  Column,
  Columns,
  Heading,
  Link,
  RichText,
  VStack,
  Wrapper,
} from "components";

const FAQs = ({ pageData }) => (
  <Wrapper id="faqs" mb={{ _: "layout.6", lg: "layout.7" }}>
    <Columns spaceX="layout.3" spaceY="layout.1">
      {pageData.faq_items.map(
        ({ answer, button_label, question, link }, index) => (
          <Column width={{ _: 1 / 1, md: 1 / 2 }} key={"faqItem" + index}>
            <Card
              width="100%"
              height="100%"
              p={{ _: "layout.3", lg: "layout.5" }}
              borderWidth={1}
              borderRadius={3}
            >
              <VStack space="layout.3">
                <Heading
                  children={question}
                  size={500}
                  minHeight={{ _: 24 * 2 + "px", xlg: 28 * 2 + "px" }}
                />
                {answer.html && <RichText html={answer.html} color="text.1" />}
                {button_label && link && link.url && (
                  <Button
                    children={button_label}
                    as={Link}
                    href={link.url}
                    intent="default"
                    appearance="default"
                    height={40}
                  />
                )}
              </VStack>
            </Card>
          </Column>
        )
      )}
    </Columns>
  </Wrapper>
);

export default FAQs;
