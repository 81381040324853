import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { ThemeProvider } from "emotion-theming";
import { ParallaxProvider } from "react-scroll-parallax";

// Include the remix icons css
import "remixicon/fonts/remixicon.css";

import {
  Box,
  ColorMode,
  Footer,
  GlobalStyles,
  Header,
  Theme,
} from "components";

const DURATION = 0.35;

const VARIANTS = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: DURATION,
      delay: DURATION,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: DURATION },
  },
};

function Layout({ children, location }) {
  return (
    <ParallaxProvider>
      <ThemeProvider theme={Theme}>
        <ColorMode mode="dark">
          <>
            <Helmet>
              <link
                href="https://fonts.googleapis.com/css2?family=Space+Mono:wght@700&display=swap"
                rel="stylesheet"
              />
              <link
                rel="stylesheet"
                href="https://use.typekit.net/bsy8arp.css"
              />
            </Helmet>
            <GlobalStyles />
            <Box display="flex" flexDirection="column" minHeight="100vh">
              <Header />
              <AnimatePresence>
                <motion.main
                  key={location.pathname}
                  variants={VARIANTS}
                  initial="initial"
                  animate="enter"
                  exit="exit"
                >
                  {children}
                </motion.main>
              </AnimatePresence>
              <Footer />
            </Box>
          </>
        </ColorMode>
      </ThemeProvider>
    </ParallaxProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
