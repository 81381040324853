import React from "react";

import {
  Box,
  Button,
  ColorMode,
  Column,
  Columns,
  Heading,
  Paragraph,
  Text,
  VStack,
  Wrapper,
} from "components";

const Newsletter = ({ pageData }) => (
  <Wrapper id="newsletter" my={{ _: "layout.6", lg: "layout.7" }}>
    <VStack space="layout.2" align="center">
      <VStack space="spacing.3" align="center">
        <Heading
          children={pageData.newsletter_title.text}
          size={700}
          maxWidth={768}
          textAlign="center"
        />
        <Paragraph
          children={pageData.newsletter_subtitle}
          size={500}
          maxWidth={512}
          textAlign="center"
          color="text.1"
        />
      </VStack>
      <Box
        as="form"
        width="100%"
        onSubmit={(e) => {
          e.preventDefault();
          window.location.href =
            "https://cdn.forms-content.sg-form.com/32893095-1529-11ea-9e3d-1a1cc0a8fcde?email=reklass@outlook.com";
        }}
      >
        <Columns space="spacing.2">
          {/* <Column width={{ _: 1 / 1, md: "auto" }}>
            <Text
              as="input"
              type="email"
              placeholder={pageData.newsletter_email_prompt}
              size={500}
              width="100%"
              minWidth={272}
              maxWidth={{ _: "none", md: 272 }}
              height={40}
              px="spacing.3"
              borderWidth={1}
              borderRadius={5}
              color="text.0"
              bg="background.1"
              css={{ appearance: "none" }}
            />
          </Column> */}
          <Column width={{ _: 1 / 1, md: "auto" }}>
            <ColorMode mode="light">
              <Button
                children={pageData.newsletter_button_label}
                type="submit"
                intent="default"
                appearance="primary"
                width={{ _: 1 / 1, md: 160 }}
                height={48}
              />
            </ColorMode>
          </Column>
        </Columns>
      </Box>
    </VStack>
  </Wrapper>
);

export default Newsletter;
