import { rgba } from "polished";

export default {
  fixed: {
    white: "#FFF",
    black: "#2c2927",
  },
  text: [rgba("#2c2927", 1), rgba("#2c2927", 0.6), rgba("#2c2927", 0.3)],
  background: ["#FFFFFF", "#f9f9f9", "#eeeded"],
  border: [rgba("#2c2927", 0.1)],
  fill: [rgba("#2c2927", 0.075), rgba("#2c2927", 0.15)],
  tint: {
    teal: "#61b9ae",
    orange: "#de5829",
  },
  modes: {
    dark: {
      fixed: {
        white: "#FFF",
        black: "#2c2927",
      },
      text: [rgba("#FFF", 0.9), rgba("#FFF", 0.6), rgba("#FFF", 0.3)],
      background: ["#2c2927", "#443e3a", "#706b68"],
      border: [rgba("#FFF", 0.2)],
      fill: [rgba("#FFF", 0.075), rgba("#FFF", 0.15)],
      tint: {
        teal: "#61b9ae",
        orange: "#de5829",
      },
    },
  },
};
