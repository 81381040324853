/**
 * Icon
 *
 * This is a fairly simple component to wrap the Box component with
 * some syntatic icing to make an icon based on the remix-icon set.
 */

import React from "react"

import { Box } from "components"

const Icon = ({ color = "text.0", size = 24, symbol = "", ...rest }) => {
  return (
    <Box
      data-component-id="icon"
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={size + "px"}
      height={size + "px"}
      fontSize={size + "px"}
      color={color}
      {...rest}
    >
      <Box
        as="i"
        className={`${"ri-" + symbol}`}
        css={{ lineHeight: size + "px" }}
      />
    </Box>
  )
}

export default Icon
