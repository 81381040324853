import React from "react"

import { Box } from "components"

const GUTTER = 32
const MARGINS = { _: "layout.1", md: "layout.2", lg: "layout.4" }

function Column() {
  return (
    <Box flex={1} height="100%" px={GUTTER / 2 + "px"}>
      <Box
        height="100%"
        bg="#FF000030"
        boxShadow="inset -1px 0 0 #FF000060, inset 1px 0 0 #FF000060"
      />
    </Box>
  )
}

function DebugGrid() {
  return (
    <Box
      data-component-id="debugGrid"
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      css={{ pointerEvents: "none" }}
    >
      <Box height="100%" px={MARGINS}>
        <Box width="100%" maxWidth={1024} height="100%" mx="auto">
          <Box display="flex" height="100%" mx={-GUTTER / 2 + "px"}>
            {/* 4 */}
            <Column />
            <Column />
            <Column />
            <Column />

            {/* 8 */}
            <Column />
            <Column />
            <Column />
            <Column />

            {/* 12 */}
            <Column />
            <Column />
            <Column />
            <Column />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default DebugGrid
