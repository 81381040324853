import React from "react";
import Image from "gatsby-image/withIEPolyfill";

import {
  Card,
  ColorMode,
  Inline,
  Paragraph,
  VStack,
  Wrapper,
} from "components";

const Sponsors = ({ pageData }) => (
  <Wrapper id="sponsors" my={{ _: "layout.6", lg: "layout.7" }}>
    <ColorMode mode="light">
      <Card px="layout.3" py="layout.5" borderRadius={3} bg="background.0">
        <VStack space="layout.3">
          <Paragraph
            children={pageData.sponsor_title}
            size={400}
            width="100%"
            maxWidth={420}
            mx="auto"
            textAlign="center"
          />
          <Inline align="center" spaceX="layout.5" spaceY="layout.3">
            {pageData.sponsors.map(({ sponsor_logo, sponsor_name }, index) => {
              if (sponsor_logo && sponsor_logo.fluid) {
                return (
                  <Card width={128} height={32} key={"sponsorLogo" + index}>
                    <Image
                      fluid={sponsor_logo.fluid}
                      alt={sponsor_name + " logo"}
                      objectFit="contain"
                      objectPosition="50% 50%"
                      style={{ height: "100%" }}
                    />
                  </Card>
                );
              } else {
                return null;
              }
            })}
          </Inline>
        </VStack>
      </Card>
    </ColorMode>
  </Wrapper>
);

export default Sponsors;
