import React from "react";

import { Box, Card, Flex, Heading, HStack, Icon } from "components";

const FundRaisingCard = ({ progress, goal, units }) => (
  <Card py="spacing.3" px="layout.1" borderRadius={3} bg="fill.0">
    <Flex width="100%" justifyContent="center">
      <HStack align="center" space="spacing.4">
        <Box>
          <Heading children={progress} as="span" size={400} />
        </Box>
        <Icon symbol="arrow-right-line" size={20} color="text.2" />
        <Box>
          <Heading children={goal} as="span" size={400} />
        </Box>
      </HStack>
    </Flex>
    {units && <Heading children={units} size={400} textAlign="center" />}
  </Card>
);

export default FundRaisingCard;
